import { useTheme } from "@mui/material";
import DSBox from "../system-design/DSBox";
import DSTypography from "../system-design/DSTypography";
import { isMobile } from "react-device-detect";
import KlydoOrderForm from "./KlydoOrderForm";
import KlydoPreview from "./KlydoPreview";
import DSCircularProgress from "../system-design/DSCircularProgress";
import { Klydo } from "../../context/klydo/KlydoTypes";
import { KlydoGiftProps } from "../../api/gift";
import { useState } from "react";

type ProductPageProps = {
  handleBuyNow: (productVariantId: string, quantity: number) => Promise<void>;
  klydo?: Partial<Klydo>;
  klydoGiftProps?: Partial<KlydoGiftProps>;
  loading?: boolean;
  isShopifyRedirect?: boolean;
};

const ProductPage: React.FC<ProductPageProps> = ({
  handleBuyNow,
  klydo,
  klydoGiftProps,
  loading,
  isShopifyRedirect,
}) => {
  const theme = useTheme();
  const [woodType, setWoodType] = useState("");

  return (
    <DSBox
      sx={{
        paddingTop: { xs: 0, md: 13 },
        justifyContent: "start",
        paddingBottom: { xs: 3, md: "0px" },
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <DSBox
        gap={isMobile ? 0.5 : 20}
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row-reverse" },
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <DSBox
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginTop: { xs: 0, md: -9 },
          }}
        >
          <DSBox>
            <DSTypography
              lineHeight={1}
              fontStyle={"bold"}
              fontSize={isMobile ? 35 : 47.52}
              fontFamily={"Gilroy-Bold"}
              maxWidth={isMobile ? "300px" : "400px"}
            >
              {isShopifyRedirect
                ? " Buy Now, Personalize Later"
                : "Almost There!"}
            </DSTypography>
          </DSBox>
          <DSBox py={1} />
          <DSBox sx={{ paddingBottom: { xs: 2 } }}>
            <DSTypography
              fontSize={isMobile ? 15 : 18}
              fontFamily={"Gilroy-Medium"}
              maxWidth={isMobile ? "330px" : "400px"}
            >
              {isShopifyRedirect
                ? "After placing the order, you’ll receive an email with a link to create your custom KlydoGreet. Add your personal touch whenever you’re ready, making this gift truly unforgettable!"
                : "Select the Klydoclock(s) you want to add your KlydoGreet to and complete your order."}
            </DSTypography>
            <DSTypography
              fontSize={isMobile ? 15 : 18}
              fontFamily={"Gilroy-Medium"}
              maxWidth={isMobile ? "330px" : "400px"}
            >
              We can’t wait to hear all about their reaction!
            </DSTypography>
          </DSBox>
          {!isMobile && (
            <KlydoOrderForm
              handleBuyNow={handleBuyNow}
              setWoodType={setWoodType}
            />
          )}
        </DSBox>
        <DSBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: isMobile ? "180px" : "290px",
              height: isMobile ? "290px" : "483px",
              position: "relative",
            }}
          >
            <DSBox py={1} />
            <KlydoPreview
              gift={klydoGiftProps}
              klydo={klydo}
              woodType={woodType}
            />
          </div>
        </DSBox>

        <DSBox py={2} />
        {isMobile && (
          <KlydoOrderForm
            handleBuyNow={handleBuyNow}
            setWoodType={setWoodType}
          />
        )}
      </DSBox>
      {loading && (
        <DSBox
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: theme.zIndex.modal,
          }}
        >
          <DSCircularProgress />
        </DSBox>
      )}
    </DSBox>
  );
};

export default ProductPage;
