import { useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DSIconButton from "./DSIconButton";
import DSBox from "./DSBox";

const QuantityInput = ({ setQuantityValue }) => {
  const [quantity, setQuantity] = useState(1);

  const handleIncrease = () => {
    const newValue = quantity + 1;
    setQuantity(newValue);
    setQuantityValue?.(newValue);
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      const newValue = quantity - 1;
      setQuantity(newValue);
      setQuantityValue?.(newValue);
    }
  };

  const handleChange = (e) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value >= 1) {
      setQuantity(value);
      setQuantityValue?.(value);
    }
  };

  return (
    <DSBox
      sx={{
        display: "flex",
        alignItems: "center",
        border: "1px solid rgba(255, 255, 255, 0.23)",
        borderRadius: "4px",
        backgroundColor: "#000",
        width: "fit-content",
      }}
    >
      <input
        type="number"
        value={quantity}
        onChange={handleChange}
        style={{
          width: "40px",
          padding: "4px 8px",
          border: "none",
          backgroundColor: "transparent",
          color: "white",
          fontSize: "16px",
          textAlign: "center",
        }}
        min="1"
      />
      <style>
        {`
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type="number"]:focus {
          outline: none;
        }
      `}
      </style>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderLeft: "1px solid rgba(255, 255, 255, 0.23)",
        }}
      >
        <DSIconButton
          onClick={handleIncrease}
          size="small"
          sx={{
            padding: "2px",
            minWidth: "24px",
            height: "16px",
            color: "white",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
          }}
        >
          <KeyboardArrowUpIcon fontSize="small" />
        </DSIconButton>
        <DSIconButton
          onClick={handleDecrease}
          disabled={quantity === 1}
          size="small"
          sx={{
            padding: "2px",
            minWidth: "24px",
            height: "16px",
            color: "white",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
          }}
        >
          <KeyboardArrowDownIcon fontSize="small" />
        </DSIconButton>
      </div>
    </DSBox>
  );
};

export default QuantityInput;
