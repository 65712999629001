import React from "react";
import GiftOccasion from "../GiftOccasion";
import GiftKlydoGreet from "../GiftKlydoGreet";
import GiftViewContainer from "../GiftViewContainer";
import WizardProductPage from "../WizardProductPage";
import GiftFormContainer from "../GiftFormContainer";
export type Step = {
  id: string;
  title?: string;
  description?: string;
  component: React.JSX.Element;
};

const steps: Step[] = [
  {
    id: "occasion",
    title: "What's the occasion?",
    description: " Choose the event that’s about to get even more memorable.",
    component: <GiftOccasion />,
  },
  {
    id: "greet",
    title: "Choose your KlydoGreet",
    description:
      "Pick the perfect animated greeting to surprise your gift recipient the moment they open their Klydoclock.",
    component: <GiftKlydoGreet />,
  },
  {
    id: "personal-greet",
    title: "Your personal greeting",
    description:
      "Add a personal message to make your KlydoGreet even more special.",
    component: <GiftFormContainer />,
  },
  {
    id: "preview",
    title: "Your KlydoGreet is ready!",
    description:
      "Here’s a preview of your KlydoGreet, looks great! Proceed to the next step to complete your gift order.",
    component: <GiftViewContainer />,
  },
  {
    id: "product-page",
    component: <WizardProductPage />,
  },
];

export default steps;
