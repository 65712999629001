import { Klydo } from "../context/klydo/KlydoTypes";
import { baseClient } from "./baseRequest";

export type KlydoApiClient = {
  getKlydo: (klydoId: string) => Promise<Klydo | null>;

  search: (
    query: string,
    filters?: {
      excludeIds?: string[];
    },
  ) => Promise<{ ids: string[] }>;
};

export type KlydoSearchFilter = { excludeIds: string[] };

const klydoApiClient: KlydoApiClient = {
  getKlydo: (klydoId: string) =>
    baseClient.get<Klydo | null>({
      url: `/klydos/${klydoId}`,
    }),
  search: async (query, { excludeIds } = {}) => {
    return baseClient.post<{ ids: string[] }>({
      url: `/klydos/.search?query=${query}`,
      body: {
        filters: { excludeIds },
      },
    });
  },
};

export default klydoApiClient;
