import { useNavigate } from "react-router-dom";
import { useHistory } from "../../../utils";
import { useGiftContext } from "../Context/GiftContextProvider";
import useOrderGift from "./useOrderGift";
import useOrderQuery from "./useOrderQuery";
import { usePrefetchProductVariants } from "./usePrefetchProductVariants";
import { useEffect } from "react";

const variantToImage = (woodType: string) => {
  if (woodType.toLowerCase().includes("walnut")) {
    return "https://res.cloudinary.com/klydoclock/image/upload/v1733216572/klydo_display_x_klydo_side_2_x_walnut_x_2000X2000_x_white_pup8j1.png";
  } else {
    return "https://res.cloudinary.com/klydoclock/image/upload/v1733216572/klydo_display_master_square_x_klydo_side_2_x_oak_x_2000X2000_x_white_background_rnvkcm.png";
  }
};

const useGiftHomePage = () => {
  const { search } = useHistory();
  const prefetchProductVariants = usePrefetchProductVariants();
  const { setKlydoGiftProps, klydoGiftProps, setSelectedKlydoGreet } =
    useGiftContext();
  const { data: orderInfo, isLoading: isLoadingOrderInfo } = useOrderQuery(
    search.orderId as string,
  );
  const { data: gift, isLoading: isLoadingGift } = useOrderGift(
    search.orderId as string,
  );
  const navigate = useNavigate();

  useEffect(() => {
    const orderId = search?.orderId;
    const clockId = search?.clockId;
    let timeZone: string;
    try {
      timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch {}
    setKlydoGiftProps({
      ...klydoGiftProps,
      orderId: orderId as string,
      clockId: clockId as string,
      timeZone,
    });
  }, []);

  const isShopifyRedirect = klydoGiftProps?.orderId === "shopify-redirect";

  useEffect(() => {
    const fetchProductVariants = async () => {
      if (isShopifyRedirect) {
        await prefetchProductVariants;
      }
    };
    fetchProductVariants();
  }, [isShopifyRedirect]);

  useEffect(() => {
    if (isShopifyRedirect) {
      const walnutImage = new Image();
      const oakImage = new Image();
      walnutImage.src = variantToImage("walnut");
      oakImage.src = variantToImage("oak");
    }
  }, [isShopifyRedirect]);

  const handleCreateGreet = () => {
    if (klydoGiftProps?.orderId === "shopify-redirect") {
      navigate(`/gift/wizard?step=occasion&orderId=shopify-redirect`);
    } else {
      navigate(`/gift/wizard?step=occasion`);
    }
  };

  const prsonalInfo = orderInfo?.data?.first_name
    ? `${orderInfo?.data?.first_name} ${orderInfo?.data?.last_name}`
    : "";

  if (isLoadingOrderInfo || isLoadingGift) {
    return { isLoading: true };
  }

  if (gift) {
    setKlydoGiftProps(gift);
    setSelectedKlydoGreet(gift.klydo);
    navigate(`/gift/done`);
  }

  return {
    isLoading: false,
    handleCreateGreet,
    prsonalInfo,
    klydoGiftProps,
    navigate,
    isShopifyRedirect,
  };
};

export default useGiftHomePage;
