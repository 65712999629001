import { Link, useTheme } from "@mui/material";
import DSBox from "../system-design/DSBox";
import DSTypography from "../system-design/DSTypography";
import { useGiftContext } from "./Context/GiftContextProvider";
import DSPaper from "../system-design/DSPaper";
import logo from "./assets/logo-05-05.png";
import { isIOS, isMobile } from "react-device-detect";
import { useState } from "react";
import DSSwitch from "../system-design/DSSwitch";
import GiftView from "./GiftView";
import DSPrimaryButton from "../system-design/DSPrimaryButtom";

const GiftDone = () => {
  const theme = useTheme();
  const { klydoGiftProps, selectedKlydoGreet } = useGiftContext();
  const [showGreeting, setShowGreeting] = useState(false);
  const handleToggle = (event) => {
    setShowGreeting(event.target.checked);
  };

  const isShopifyRedirect = klydoGiftProps?.orderId
    ?.toLowerCase()
    ?.includes("shopify");

  return (
    <DSPaper
      style={{
        height: "100vh",
        overflow: "scroll",
      }}
    >
      <DSBox
        color="transparent"
        sx={{
          backgroundColor: theme.palette.background.paper,
          paddingY: 4,
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",
          boxShadow: "none",
        }}
      >
        <img width={isMobile ? "120" : "150"} src={logo} />
      </DSBox>
      <DSBox
        sx={{
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",
          backgroundColor: theme.palette.background.paper,
          height: "100vh",
        }}
      >
        <DSBox
          gap={isMobile ? 0.5 : 20}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row-reverse" },
            paddingTop: { md: 8 },
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <DSBox
            sx={{
              flexDirection: "column",
              paddingTop: { md: 2 },
            }}
          >
            <DSBox>
              <DSTypography
                lineHeight={1}
                fontStyle={"bold"}
                fontSize={47.52}
                fontFamily={"Gilroy-Bold"}
              >
                Your KlydoGreet
              </DSTypography>

              <DSTypography
                lineHeight={1}
                fontStyle={"bold"}
                fontSize={47.52}
                fontFamily={"Gilroy-Bold"}
              >
                is ready!
              </DSTypography>
            </DSBox>
            <DSBox py={1} />
            <DSBox sx={{ paddingBottom: { xs: 2 } }}>
              <DSTypography fontSize={18} fontFamily={"Gilroy-Medium"}>
                Your personalized gift is
              </DSTypography>
              <DSTypography fontSize={18} fontFamily={"Gilroy-Medium"}>
                ready! We can't wait to hear
              </DSTypography>
              <DSTypography fontSize={18} fontFamily={"Gilroy-Medium"}>
                all about their reaction!
              </DSTypography>
            </DSBox>
            {!isMobile && !isShopifyRedirect && (
              <DSBox>
                <DSTypography
                  mb={1}
                  fontSize={15}
                  fontFamily={"Roboto-Regular"}
                >
                  Have questions or something to share?
                </DSTypography>
                <DSTypography
                  mb={1}
                  fontSize={15}
                  fontFamily={"Roboto-Regular"}
                >
                  We'd love to hear from you!
                </DSTypography>
                <DSTypography
                  mb={1}
                  fontSize={15}
                  fontFamily={"Roboto-Regular"}
                >
                  <Link
                    color="inherit"
                    href="https://www.klydoclock.com/pages/contact-us"
                  >
                    Contact Us
                  </Link>
                </DSTypography>
              </DSBox>
            )}
            {!isMobile && isShopifyRedirect && (
              <DSPrimaryButton
                onClick={() => {
                  window.location.href = "https://www.klydoclock.com/cart";
                }}
              >
                Get back to cart
              </DSPrimaryButton>
            )}
          </DSBox>
          <DSBox
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: isIOS && isMobile ? "250px" : "290px",
                height: isIOS && isMobile ? "420px" : "483px",
                position: "relative",
              }}
            >
              <GiftView
                gift={klydoGiftProps}
                klydo={selectedKlydoGreet}
                isToggled={showGreeting}
              />
            </div>
            <DSBox
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                width: "100%",
              }}
            >
              <DSTypography
                fontFamily={"Gilroy-SemiBold"}
                fontSize={"16px"}
                color={showGreeting ? "#A5A5A5" : "#FFFFFF"}
              >
                Greet
              </DSTypography>

              <DSSwitch checked={showGreeting} onChange={handleToggle} />

              <DSTypography
                fontFamily={"Gilroy-SemiBold"}
                fontSize={"16px"}
                color={showGreeting ? "#FFFFFF" : "#A5A5A5"}
              >
                Message
              </DSTypography>
            </DSBox>
          </DSBox>

          <DSBox py={1} />
          {isShopifyRedirect && isMobile && (
            <DSPrimaryButton
              sx={{ fontSize: "14px" }}
              onClick={() => {
                window.location.href = "https://www.klydoclock.com/cart";
              }}
            >
              Back to cart
            </DSPrimaryButton>
          )}
          {isMobile && !isShopifyRedirect && (
            <DSBox>
              <DSTypography mb={1} fontSize={15} fontFamily={"Gilroy-light"}>
                Have questions or something to share?
              </DSTypography>
              <DSTypography mb={1} fontSize={15} fontFamily={"Gilroy-light"}>
                We'd love to hear from you!
              </DSTypography>
              <DSTypography mb={1} fontSize={15} fontFamily={"Gilroy-light"}>
                <Link
                  color="inherit"
                  href="https://www.klydoclock.com/pages/contact-us"
                >
                  Contact Us
                </Link>
              </DSTypography>
            </DSBox>
          )}
        </DSBox>
      </DSBox>
    </DSPaper>
  );
};
export default GiftDone;
