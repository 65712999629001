import { useGiftContext } from "./Context/GiftContextProvider";
import { useEffect, useState } from "react";
import giftClient from "../../api/gift";
import { generateRandomNumberString, useHistory } from "../../utils";
import ProductPage from "./ProductPage";

const WizardProductPage = () => {
  const { setQueryParam } = useHistory();
  const { klydoGiftProps, selectedKlydoGreet } = useGiftContext();
  const [giftId, setGiftId] = useState("");
  const [loading, setLoading] = useState(false);

  const handleBuyNow = async (productVariantId: string, quantity: number) => {
    const randomId = generateRandomNumberString();
    setGiftId(randomId);
    const orderId = `ShopifyRedirect${randomId}`;
    setQueryParam("orderId", orderId, false);

    setLoading(true);
    await giftClient.createGiftKlydo({
      ...klydoGiftProps,
      orderId: orderId,
      giftId: giftId,
    });
    const checkoutUrl = `https://www.klydoclock.com/cart/add?id=${productVariantId}&quantity=${quantity}&properties[gift_id]=${orderId}`;
    if (window.self !== window.top) {
      window.parent.location.href = checkoutUrl;
    } else {
      window.location.href = checkoutUrl;
    }
  };

  return (
    <ProductPage
      handleBuyNow={handleBuyNow}
      klydoGiftProps={klydoGiftProps}
      loading={loading}
      klydo={selectedKlydoGreet}
    />
  );
};
export default WizardProductPage;
