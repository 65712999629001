import { useTheme } from "@mui/material";
import { useGiftContext } from "../Context/GiftContextProvider";
import { useEffect, useRef, useState } from "react";
import useFileUpload from "./useFileUpload";
import { Dayjs } from "dayjs";

const useGiftForm = () => {
  const defaultPhoto =
    "https://res.cloudinary.com/klydoclock/image/upload/profile/defaultPhoto_mc2iv6.png";
  const theme = useTheme();
  const { setKlydoGiftProps, klydoGiftProps, resetSenderImageUrl, setLoading } =
    useGiftContext();
  const [greeting, setGreeting] = useState(
    klydoGiftProps?.greetingContent || "",
  );
  const [name, setName] = useState(klydoGiftProps?.senderName || "");
  const [profileUrl, setProfileUrl] = useState(klydoGiftProps?.senderImageUrl);

  const fileInputRef = useRef(null);
  const { handleFileSelection: handleProfileUpload } = useFileUpload({
    folder: "profile",
    uploadPreset: "GiftPicture",
    setUrl: setProfileUrl,
    setLoading: setLoading,
    onSuccess: (url) => {
      setKlydoGiftProps({
        senderImageUrl: url,
      });
    },
  });

  const handleAvatarClick = () => fileInputRef.current?.click();

  const handleGreetChange = (greet: string) => {
    if (greet.length > 163) return alert("Greeting is too long");
    setGreeting(greet);
    setKlydoGiftProps({
      greetingContent: greet,
    });
  };

  const handleNameChange = (senderName: string) => {
    if (senderName.length > 14) return alert("Name is too long");
    setName(senderName);
    setKlydoGiftProps({
      senderName: senderName,
    });
  };

  const handleDateChange = (selectedDate: Dayjs) => {
    setKlydoGiftProps({
      date: selectedDate?.valueOf(),
    });
  };

  const handleRemoveProfile = () => {
    setProfileUrl(defaultPhoto);
    resetSenderImageUrl();
    if (fileInputRef.current) fileInputRef.current.value = "";
  };

  const handlePickProfile = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleProfileUpload(event);
  };

  return {
    theme,
    greeting,
    name,
    profileUrl,
    fileInputRef,
    klydoGiftProps,
    defaultPhoto,
    handleAvatarClick,
    handleGreetChange,
    handleNameChange,
    handleDateChange,
    handleRemoveProfile,
    handlePickProfile,
  };
};
export default useGiftForm;
