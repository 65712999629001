import { useQuery } from "@tanstack/react-query";
import { Klydo } from "../context/klydo/KlydoTypes";
import klydoApiClient from "../api/klydo";

const useKlydo = (klydoId: string) => {
  return useQuery<Klydo>({
    queryKey: ["klydo", klydoId],
    queryFn: () => klydoApiClient.getKlydo(klydoId),
    enabled: !!klydoId,
  });
};

export default useKlydo;
