import { useGiftContext } from "./Context/GiftContextProvider";
import AdminGiftForm from "./AdminGiftForm";
import GiftForm from "./GiftForm";

const GiftFormContainer = () => {
  const { admin, klydoGiftProps } = useGiftContext();
  const isShopifyRedirect = klydoGiftProps?.orderId === "shopify-redirect";

  return <>{admin && !isShopifyRedirect ? <AdminGiftForm /> : <GiftForm />}</>;
};

export default GiftFormContainer;
